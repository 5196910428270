import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import _ from "lodash";
import styled, { keyframes } from "styled-components";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 12px 24px;
  border-radius: 10px;
  border: none;
  background-color: var(--secondary);
  font-weight: bold;
  font-size: 1.5em;
  color: var(--secondary-text);
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(0, 0, 0, 0.7);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 260px;
  margin-top: 24px;
  @media (min-width: 767px) {
    width: 500px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const CTA = styled.img`
  margin: 10vh;
  rotate: 3deg;
  max-width: 700px;
`;

export const Roadmap = styled.img`
  margin: 10vh;
  margin-bottom: 0;
  max-width: 950px;
`;

export const Socials = styled.img`
  max-width: 35px;
  margin: 5px;
  opacity: 30%;
`;

export const RoadmapMax = styled.img`
  max-width: 100%;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App({ propCAATOKEN }) {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "TBD",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
    MINTABLE: false,
    ROADMAP: false,
    TOKEN_ADDRESS: "TBD",
  });
  const CAATOKEN = propCAATOKEN || "TBD";

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const [imageSrc, setImageSrc] = useState("./config/images/flying.gif");

  const handleImageClick = () => {
    setImageSrc("./config/images/puking.gif");
  };

  const CopyInputBox = ({ token }) => {
    const [value, setValue] = useState(token);
    const [showCopied, setShowCopied] = useState(false);

    const handleCopy = () => {
      setShowCopied(true);
      navigator.clipboard.writeText(value).catch((err) => {
        console.error("Failed to copy: ", err);
      });
    };

    return (
      <div style={{ position: "relative" }}>
        <input
          type="text"
          value={value}
          readOnly
          onClick={handleCopy}
          style={{
            padding: "10px",
            fontSize: "36px",
            backgroundColor: "white",
            border: "6px solid #333",
            width: "33vw",
            minWidth: "600px",
            textAlign: "center",
            cursor: "pointer",
          }}
        />
        {showCopied && (
          <span
            style={{
              position: "absolute",
              top: "64px",
              right: 0,
              transform: "translateX(-50%)",
              backgroundColor: "#333",
              color: "white",
              padding: "5px 15px",
              marginTop: "5px",
              fontSize: "18px",
            }}
          >
            Copied!
          </span>
        )}
      </div>
    );
  };

  const getConfig = async () => {
    const configResponse = await fetch("./config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const [marketCap, setMarketCap] = useState(0);

  const getMarketCap = async () => {
    try {
      const response = await fetch(
        `https://api.dexscreener.com/latest/dex/tokens/${CONFIG.CONTRACT_ADDRESS}`
      );
      const data = await response.json();
      const marketCap = _.first(data.pairs).marketCap;
      setMarketCap(marketCap);
    } catch (error) {
      console.error("Error fetching market cap:", error);
    }
  };

  const AngledDiv = styled.div`
    scale: ${(props) => props.width / 180};
    position: absolute;
    right: ${(props) => (props.width / 180) * 30}%;
    top: ${(props) => (props.width / 180) * 40}%;
    width: 180px;
    padding: 15px;
    background: white;
    border: 5px solid #333;
    animation: growShrink 4s infinite;
    rotate: 17deg;
  `;

  const [width, setWidth] = useState(180); // Initial width

  useEffect(() => {
    const handleResize = () => {
      const newWidth = (window.innerWidth / 1080) * 180; // Compute width based on window size
      setWidth(newWidth > 180 ? 180 : newWidth); // Cap the width at 180px
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Set initial width

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  useEffect(() => {
    if (CONFIG.CONTRACT_ADDRESS && CONFIG.MAX_SUPPLY) {
      getMarketCap();
    }
  }, [CONFIG]);

  const imageRef = useRef(null);
  const imageRef2 = useRef(null);

  const qaData = [
    {
      question: "What is Little Turtle Club?",
      answer: [
        "The Little Turtle Club is a fun, community-driven meme coin focused on positive vibes and steady growth. 🐢💚 Join us for laughs, great community energy, and a chance to ride the crypto wave with the coolest club around! 🌊💥",
      ],
    },
    {
      question: "How do I buy the coin?",
      answer: [
        `To buy The Little Turtle Club on Solana:`,
        `1. Set up a Solana wallet (e.g., Phantom).`,
        `2. Add SOL to your wallet.`,
        `3. Use a Solana DEX (like Serum or Radium).`,
        `4. Swap SOL for The Little Turtle Club token using our official address.`,
      ],
    },
    {
      question: "What are the benefits of using Little Turtle Club?",
      answer: [
        `The benefits of The Little Turtle Club include:`,
        `1. Community-Driven: A fun, supportive group of turtle lovers.`,
        `2. Exclusive NFTs: Top holders get access to limited-edition NFTs.`,
        `3. Steady Growth: Focused on long-term growth, just like our turtle mascot.`,
        `4. Meme Power: Ride the meme wave with a coin that keeps it light-hearted!`,
        `5. Burn Milestones: Key burn events at market cap milestones to increase scarcity and value.`,
        `6. Donations: We give back by donating to environmental causes, supporting turtle conservation efforts.`,
      ],
    },
  ];

  const QASection = ({ data }) => {
    return (
      <ResponsiveWrapper>
        <div
          style={{
            maxWidth: "1000px",
            margin: "auto",
            padding: "20px",
            marginTop: "96px",
          }}
        >
          <h2 style={{ fontSize: "72px", textAlign: "center" }}>
            Frequently Asked Questions
          </h2>
          {data.map((item, index) => (
            <div key={index} style={{ marginBottom: "20px" }}>
              <h3
                style={{
                  padding: "24px",
                  fontSize: "36px",
                }}
              >
                {item.question}
              </h3>
              <ul>
                {item.answer.map((answerItem, answerIndex) => (
                  <li
                    style={{
                      padding: answerIndex === 0 ? "6px 24px" : "6px 48px",
                      fontSize: "24px",
                    }}
                    key={answerIndex}
                  >
                    {answerItem}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </ResponsiveWrapper>
    );
  };

  const ProgressBarContainer = styled.div`
    width: 100%;
    background-color: #e0e0e0;
    position: fixed;
    bottom: 0;
    z-index: 9999;
  `;

  const Paragraph = styled.p`
    position: absolute;
    bottom: 3px;
    right: 10px;
    font-size: 14px;
    font-weight: bold;
    font-family: arial;
  `;

  const ProgressBarFiller = styled.div`
    height: 20px;
    width: ${(props) => props.percentage}%;
    background-color: #333;
    transition: width 0.5s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: start;
    color: white;
    font-family: arial;
    font-weight: bold;
    font-size: 14px;
    padding-left: 10px;
    position: relative;
  `;

  const ProgressBarImage = styled.img`
    position: absolute;
    right: -60px;
    top: -69px;
    width: 180px;
    transform: scaleX(-1);
  `;

  const OverlayText = styled.div`
    position: absolute;
    opacity: ${(props) => (props.opacity ? "10%" : "100%")};
    top: ${(props) => props.top || "auto"};
    left: ${(props) => props.left || "auto"};
    right: ${(props) => props.right || "auto"};
    bottom: ${(props) => props.bottom || "auto"};
    color: ${(props) => props.color || "black"};
    font-size: ${(props) => props.fontSize || "16px"};
    max-width: 800px;
    z-index: 3;
    white-space: pre-wrap; /* Preserve line breaks and whitespace */
    line-height: 1.5;
    animation: ${(props) => (props.animate ? slideIn : slideIn)} 1.5s forwards;
  `;

  const [activeTab, setActiveTab] = useState(1);

  const tabs = [
    {
      id: 0,
      label: "Chapter 1",
      tabImage: "./config/images/fg1.gif",
      images: [
        {
          src: "./config/images/bg1.jpg",
          width: "100%",
          height: "100%",
        },
        {
          src: "./config/images/fg1.gif",
          width: "6%",
          bottom: "5%",
          left: "49%",
        },
      ],
      text: {
        title: "Chapter 1: The Prophecy",
        content: `Under the shadow of a dim, cursed moon, Toby the turtle was born with a prophecy: 
        
        "Return the Moonstone to the moon and restore its light, guiding the tides and the digital seas."
        
        
        Milestone hit, chapter 2 launching soon!
        `,
        top: "15%",
        color: "white",
        fontSize: "24px",
      },
    },
    {
      id: 1,
      label: "Chapter 2",
      tabImage: "./config/images/fg2.png",
      images: [
        {
          src: "./config/images/cave.jpg",
          width: "100%",
          height: "100%",
        },
        {
          src: "./config/images/elder.gif",
          width: "20%",
          bottom: "10%",
          left: "47%",
        },
        {
          src: "./config/images/moonstone.gif",
          width: "10%",
          bottom: "2%",
          left: "55%",
        },
        {
          src: "./config/images/tobyfg_looking.gif",
          width: "10%",
          bottom: "2%",
          left: "47%",
        },
      ],
      text: {
        title: "Chapter 2: Moonstone's Call",
        content: `Elder Shellius gave Toby the glowing Moonstone.
"Rebuild the ancient spacecraft in the cliffs," he said, "and carry this to the moon to restore the moon's light."

Determined, Toby began uncovering the wreckage, guided by the Moonstone’s faint glow.
`,
        top: "15%",
        color: "white",
        fontSize: "24px",
        ca: "n/a",
      },
    },
    {
      id: 2,
      label: "Chapter 3",
      unavailable: true,
      tabImage: "./config/images/moonstone.gif",
      images: [
        {
          src: "./config/images/ave2c.png",
          width: "100%",
          height: "100%",
        },
      ],
      text: {
        title: "Chapter 3: TBD",
        content: `Unlocks when chapter 2 hits 100k`,
        top: "15%",
        color: "white",
        fontSize: "24px",
        ca: "n/a",
      },
    },
    {
      id: 3,
      label: "Chapter 4",
      tabImage: "./config/images/locked.gif",
      disabled: true,
      images: [],
    },
    {
      id: 4,
      label: "Chapter 5",
      tabImage: "./config/images/locked.gif",
      disabled: true,
      images: [],
    },
  ];

  // Define keyframes for sliding animation
  const slideIn = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
  `;

  const slideOut = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
`;

  const TabContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
  `;

  const TabList = styled.div`
    min-width: 180px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 344;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  `;

  const TabImage = styled.img`
    width: 50px;
    height: 50px;
  `;

  const Tab = styled.button`
    width: 100%;
    min-height: 50px;
    min-width: 180px;
    margin: 10px 0;
    padding-right: 20px;
    background-color: ${(props) => (props.active ? "#888" : "#ddd")};
    opacity: ${(props) => (props.disabled ? "30%" : "100%")};
    border: none;
    cursor: pointer;
    font-size: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: #eee;
    }
  `;

  const ContentContainer = styled.div`
    background-color: #000;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    position: relative;
    overflow: hidden;
  `;

  const BackgroundImage = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(${(props) => props.src});
    background-size: cover; /* Maintain aspect ratio while full width */
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
    animation: ${(props) => (props.animate ? slideIn : slideIn)} 2s forwards;
  `;

  const ContentImage = styled.img`
    position: absolute;
    width: ${(props) => props.width || "auto"};
    height: ${(props) => props.height || "auto"};
    top: ${(props) => props.top || "auto"};
    left: ${(props) => props.left || "auto"};
    right: ${(props) => props.right || "auto"};
    bottom: ${(props) => props.bottom || "auto"};
    z-index: 2;
    animation: ${(props) => (props.animate ? slideIn : slideIn)} 10s forwards;
  `;

  const progressPercentage = (marketCap / 1000000) * 100;

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight") {
        setActiveTab((prevTab) => {
          let nextTab = (prevTab + 1) % tabs.length;
          while (tabs[nextTab].disabled) {
            nextTab = (nextTab + 1) % tabs.length;
          }
          return nextTab;
        });
      } else if (event.key === "ArrowLeft") {
        setActiveTab((prevTab) => {
          let nextTab = (prevTab - 1 + tabs.length) % tabs.length;
          while (tabs[nextTab].disabled) {
            nextTab = (nextTab - 1 + tabs.length) % tabs.length;
          }
          return nextTab;
        });
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [tabs.length]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const image = imageRef.current;
      const image2 = imageRef2.current;

      if (image) {
        const windowHeight = window.innerHeight;
        const imageHeight = image.offsetHeight;
        const imageTop = image.getBoundingClientRect().top + scrollPosition;
        const windowCenter = scrollPosition + windowHeight / 2;

        image.style.left = 75 - (imageTop / windowCenter) * 50 + "%";
      }

      if (image2) {
        const windowHeight = window.innerHeight;
        const imageHeight = image2.offsetHeight;
        const imageTop = image2.getBoundingClientRect().top + scrollPosition;
        const windowCenter = scrollPosition + windowHeight / 2;

        image2.style.right = 50 - (imageTop / windowCenter) * 50 + "%";
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <s.Screen>
      <s.Container>
        <TabContainer>
          <h1
            style={{
              position: "absolute",
              color: "white",
              zIndex: 3,
              left: 10,
              top: 10,
            }}
          >
            Toby the Turtle: The Darkened Moon
          </h1>

          <h1
            style={{
              position: "absolute",
              color: "white",
              zIndex: 3,
              right: 10,
              bottom: 10,
            }}
          >
            Scroll down to learn more
          </h1>

          <TabList>
            {tabs.map((tab) => (
              <Tab
                key={tab.id}
                active={activeTab === tab.id}
                disabled={tabs[activeTab].disabled}
                onClick={() => (tab.disabled ? "" : setActiveTab(tab.id))}
              >
                <TabImage src={tab.tabImage} alt={tab.label} />
                {tab.label}
              </Tab>
            ))}
          </TabList>
          <ContentContainer>
            {tabs[activeTab].notAvailable && (
              <div
                style={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  zIndex: 5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.8)",
                }}
              >
                <img
                  style={{ maxWidth: "150px", height: "auto" }}
                  src="./config/images/locked.gif"
                />
              </div>
            )}

            {tabs[activeTab].images.slice(1).map((image, index) => (
              <ContentImage
                key={index}
                src={image.src}
                width={image.width}
                height={image.height}
                top={image.top}
                left={image.left}
                right={image.right}
                bottom={image.bottom}
              />
            ))}
            <OverlayText
              opacity={tabs[activeTab].opacity}
              top={tabs[activeTab].text.top}
              left={tabs[activeTab].text.left}
              right={tabs[activeTab].text.right}
              bottom={tabs[activeTab].text.bottom}
              color={tabs[activeTab].text.color}
              fontSize={tabs[activeTab].text.fontSize}
            >
              <h1 style={{ fontSize: "36px" }}>{tabs[activeTab].text.title}</h1>
              <p style={{ paddingBottom: "36px" }}>
                {tabs[activeTab].text.content}
              </p>

              <s.Container flex={1} ai={"center"}>
                {tabs[activeTab].text.ca && (
                  <CopyInputBox token={tabs[activeTab].text.ca} />
                )}
              </s.Container>
            </OverlayText>
            <BackgroundImage src={tabs[activeTab].images[0].src} />
          </ContentContainer>
        </TabContainer>
      </s.Container>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "./config/images/bg.png" : null}
      >
        {/* <StyledLogo alt={"logo"} src={"./config/images/logo.png"} /> */}
        <div
          style={{
            position: "fixed",
            right: "30px",
            top: 0,
            paddingTop: "24px",
            zIndex: 9999,
          }}
        >
          <a href="https://twitter.com/LittleTurtleSol" target="_blank">
            <Socials alt={"example"} src={"./config/images/twitter.png"} />
          </a>
          <a href={`https://pump.fun/coin/${CAATOKEN}`} target="_blank">
            <Socials alt={"example"} src={"./config/images/discord.png"} />
          </a>
          <a
            href={`https://bullx.io/terminal?chainId=1399811149&address=${CAATOKEN}`}
            target="_blank"
          >
            <Socials alt={"example"} src={"./config/images/bullx.png"} />
          </a>
          <a href={`https://gmgn.ai/sol/token/${CAATOKEN}`} target="_blank">
            <Socials alt={"example"} src={"./config/images/gmgn.png"} />
          </a>
          {/* <a
            href={`https://dexscreener.com/solana/${CAATOKEN}`}
            target="_blank"
          >
            <Socials alt={"example"} src={"./config/images/dex.png"} />
          </a> */}
        </div>
        <ResponsiveWrapper flex={1} style={{ padding: 24 }}>
          <s.Container flex={1} ai={"center"}>
            <CTA alt={"Little Turtles"} src={"./config/images/turtlex4.gif"} />
            <p style={{ textAlign: "center" }}>
              <s.TextDescription style={{ fontSize: "3em" }}>
                We like turtles.<br></br>
              </s.TextDescription>

              <CopyInputBox token={CAATOKEN} />
            </p>

            <s.Container
              style={{ padding: 24, maxWidth: "768px", fontSize: "2em" }}
            ></s.Container>
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerSmall />
        {CONFIG.MINTABLE ? (
          <ResponsiveWrapper
            flex={1}
            ai={"center"}
            jc={"center"}
            style={{ padding: 24, maxWidth: "1200px" }}
          >
            <s.SpacerLarge />
            <s.Container
              flex={1}
              jc={"center"}
              ai={"center"}
              style={{
                backgroundColor: "var(--accent)",
                padding: 24,
                boxShadow: "0px 5px 11px 2px rgba(0, 0, 0, 0.7)",
              }}
            >
              <StyledImg
                alt={"Little Turtle Club"}
                src={"./config/images/example.gif"}
              />

              <s.TextTitle
                style={{
                  textAlign: "center",
                  fontSize: 50,
                  fontWeight: "bold",
                  color: "var(--accent-text)",
                }}
              >
                {data.totalSupply > 0 ? data.totalSupply : "????"} /{" "}
                {CONFIG.MAX_SUPPLY}
              </s.TextTitle>
              <s.TextDescription
                style={{
                  textAlign: "center",
                  color: "var(--primary-text)",
                }}
              >
                <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                  {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
                </StyledLink>
              </s.TextDescription>
              {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
                <>
                  <s.TextTitle
                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                  >
                    The sale has ended.
                  </s.TextTitle>
                  <s.TextDescription
                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                  >
                    You can still find {CONFIG.NFT_NAME} on
                  </s.TextDescription>
                  <s.SpacerSmall />
                  <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                    {CONFIG.MARKETPLACE}
                  </StyledLink>
                </>
              ) : (
                <>
                  <s.TextTitle
                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                  >
                    1 {CONFIG.SYMBOL} costs {CONFIG.DISPLAY_COST}{" "}
                    {CONFIG.NETWORK.SYMBOL}.
                  </s.TextTitle>
                  {blockchain.account === "" ||
                  blockchain.smartContract === null ? (
                    <s.Container ai={"center"} jc={"center"}>
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        Connect to the {CONFIG.NETWORK.NAME} network
                      </s.TextDescription>
                      <s.SpacerSmall />
                      <StyledButton
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(connect());
                          getData();
                        }}
                      >
                        CONNECT
                      </StyledButton>
                      {blockchain.errorMsg !== "" ? (
                        <>
                          <s.SpacerSmall />
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {blockchain.errorMsg}
                          </s.TextDescription>
                        </>
                      ) : null}
                    </s.Container>
                  ) : (
                    <>
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {feedback}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <s.Container ai={"center"} jc={"center"} fd={"row"}>
                        <StyledRoundButton
                          style={{ lineHeight: 0.4 }}
                          disabled={claimingNft ? 1 : 0}
                          onClick={(e) => {
                            e.preventDefault();
                            decrementMintAmount();
                          }}
                        >
                          -
                        </StyledRoundButton>
                        <s.SpacerMedium />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {mintAmount}
                        </s.TextDescription>
                        <s.SpacerMedium />
                        <StyledRoundButton
                          disabled={claimingNft ? 1 : 0}
                          onClick={(e) => {
                            e.preventDefault();
                            incrementMintAmount();
                          }}
                        >
                          +
                        </StyledRoundButton>
                      </s.Container>
                      <s.SpacerSmall />
                      <s.Container ai={"center"} jc={"center"} fd={"row"}>
                        <StyledButton
                          disabled={claimingNft ? 1 : 0}
                          onClick={(e) => {
                            e.preventDefault();
                            claimNFTs();
                            getData();
                          }}
                        >
                          {claimingNft ? "BUSY" : "BUY"}
                        </StyledButton>
                      </s.Container>
                    </>
                  )}
                </>
              )}
              <s.SpacerMedium />
            </s.Container>
          </ResponsiveWrapper>
        ) : (
          ""
        )}
        <s.SpacerMedium />
        {/* <AngledDiv width={width}>
          <u>10% of all profits</u> the dev makes goes to charity to saving the
          turtles.
        </AngledDiv> */}
        <ResponsiveWrapper>
          <p
            style={{
              fontSize: "3em",
              maxWidth: "1000px",
              margin: "auto",
              padding: "36px",
              lineHeight: "72px",
            }}
          >
            Welcome to The Little Turtle Club, the memecoin inspired by Toby the
            Turtle’s legendary journey to restore the moon’s light and balance
            the digital seas! 🌕🐢
            <br></br>
          </p>
        </ResponsiveWrapper>
        <div className="scrolling-image-container">
          <img
            src="./config/images/199.png"
            alt="Scrolling Image"
            className="scrolling-image"
            ref={imageRef}
          />
        </div>
        <ResponsiveWrapper>
          <p
            style={{
              fontSize: "3em",
              maxWidth: "1000px",
              margin: "auto",
              padding: "36px",
              lineHeight: "72px",
            }}
          >
            <br></br>
            This isn’t just a coin;
            <br></br>it’s a movement for the homies who care about the planet
            and their wallets. 10% of what dev sells goes to charity to save the
            turtles.
            <br></br>
            <br></br>
            So, if you’re about that “get-rich-while-doing-good” energy, hop on
            the wave and let’s make saving turtles the next big flex. 🌊✨
          </p>
        </ResponsiveWrapper>
        <ResponsiveWrapper>
          <div className="scrolling-image-container">
            <img
              src={imageSrc}
              alt="Scrolling Image"
              className="scrolling-image-left"
              ref={imageRef2}
              onClick={handleImageClick}
            />
          </div>
        </ResponsiveWrapper>
        <s.Container>
          <h2
            style={{
              fontSize: "72px",
              margin: "auto",
              padding: "24px",
              textAlign: "center",
              marginTop: "72px",
              maxWidth: "1200px",
            }}
          >
            The Roadmap
          </h2>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <p className="duo-column-cta-2">
              When the current chapter coin reaches $100K MC, the dev team will
              consult MoonstoneAI to decide the next step: burn, liquidate, or
              airdrop the coins.
              <br />
              <br />
              Afterward, we’ll set a new launch date for the next chapter of the
              story, complete with a coin tied to the continuation of Toby’s
              legendary journey! 🌕🐢
            </p>
          </div>
        </s.Container>
        <ResponsiveWrapper
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "72px",
            maxWidth: "1440px",
          }}
        ></ResponsiveWrapper>
        <ResponsiveWrapper
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "72px",
            maxWidth: "968px",
          }}
        >
          <div className="column" style={{ width: "100%" }}></div>
        </ResponsiveWrapper>
        <QASection data={qaData} />

        {marketCap > 0 && (
          <ProgressBarContainer>
            <Paragraph>1M MC Goal</Paragraph>
            <ProgressBarFiller percentage={progressPercentage}>
              ${marketCap.toLocaleString()} MC
              <ProgressBarImage
                src="./config/images/flying.gif"
                alt="Tip Image"
              />
            </ProgressBarFiller>
          </ProgressBarContainer>
        )}
        {/* <ResponsiveWrapper
          style={{
            display: "block",
            padding: "72px",
            maxWidth: "968px",
          }}
        >
          <h3
            style={{
              padding: "36px",
              fontSize: "72px",
              textAlign: "center",
            }}
          >
            What happens if we miss the mark?
          </h3>
          <div style={{ textAlign: "center" }}>
            <p
              style={{
                padding: "0 36px",
                fontSize: "24px",
                lineHeight: "36px",
                display: "inline-block",
              }}
            >
              If at first you don't succeed, try, try again
            </p>
            <p>Edward Hickson</p>

            <p
              style={{
                padding: "0 36px",
                fontSize: "24px",
                lineHeight: "36px",
                display: "inline-block",
              }}
            >
              We try it again! Until we hit our 1M goal.
            </p>
          </div>
        </ResponsiveWrapper> */}
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          {/* <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            Please make sure you are connected to the right network (
            {CONFIG.NETWORK.NAME} Mainnet) and the correct address. Please note:
            Once you make the purchase, you cannot undo this action.
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            We have set the gas limit to {CONFIG.GAS_LIMIT} for the contract to
            successfully mint your NFT. We recommend that you don't lower the
            gas limit.
          </s.TextDescription> */}
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default App;
